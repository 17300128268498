.page-hero {
    position: relative;
    height: 200px;

    @include media-breakpoint-up(md) {
        height: 500px;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }
}