﻿// Colors
$colors: (
    "white": (
        "default": #ffffff
    ),
    "black": (
        "default": #000000
    ),
    "gray": (
        "lighter": #eeede8,
        "light": #d9d5c9,
        "default": #a9a69c,
        "dark": #706d66
    ),
    "brand": (
        "primary": #093a59,
        "secondary": #fec900
    ),
    "validation": (
        "valid": #39c974,
        "valid-dark": darken(#39c974, 25%),
        "invalid": #e44c41,
        "invalid-dark": darken(#e44c41, 25%),
        "warning": #f0c330,
        "info": #3a99d8
    ),
    "social": (
        "facebook": #3b5998,
        "twitter": #1da1f2,
        "instagram": #c13584,
        "youtube": #ff0000,
        "linkedin": #0077b5
    ),
);

$body-bg: map-deep-get($colors, "white", "default");
$body-color: map-deep-get($colors, "black", "default");
$link-color: map-deep-get($colors, "brand", "primary");

// Fonts
$font-family-base: "Helvetica Neue", "Arial", sans-serif;
$font-family-heading: "HighVoltage Rough", "Arial", sans-serif;

// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-cubic-smoothing: cubic-bezier(.2, 0, 0, 1);
$transition-cubic-time: .2s;
$transition-cubic: all $transition-cubic-time $transition-cubic-smoothing;

// Border Radius
$border-radius: 4px;

$enable-caret: false;
$enable-shadows: false;
$enable-rounded: false;
$enable-gradients: false;
$enable-transitions: false;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-print-styles: false;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths: (
    lg: 1200px
);

//
// Grid
$grid-columns: 24;
$grid-gutter-width: 20px;