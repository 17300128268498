.blockquote {
    position: relative;
    z-index: 1;
    padding: 40px 40px 40px 48px;
    margin-bottom: 70px;
    font-family: $font-family-heading;
    font-size: rem-calc(38);
    line-height: 1.26em;
    text-indent: -.5em;
    color: map-deep-get($colors, "white", "default");
    background-color: map-deep-get($colors, "brand", "primary");

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: map-deep-get($colors, "brand", "primary");
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        bottom: -20px;
        left: -20px;
        width: 132px;
        height: 132px;
        background-color: map-deep-get($colors, "brand", "secondary");
    }
}