.home-hero {
    margin-bottom: 120px;

    &__inner {
        position: relative;
        height: 650px;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: -60% 0;

        @include media-breakpoint-up(md) {
            background-position: center;
        }
    }

    &__title-wrapper {
        position: relative;
        z-index: 1;
        padding: 64px 40px 56px;
        background: map-deep-get($colors, "brand", "primary");
    }

    &__title {
        margin-top: 0;
        margin-bottom: rem-calc(12);
        color: map-deep-get($colors, "white", "default");
    }

    &__subtitle {
        margin-top: 0;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &__button-wrapper {
        display: inline-block;
        position: relative;
        padding: 24px 64px 24px 0;
        background: map-deep-get($colors, "brand", "secondary");

        &::before {
            content: "";
            position: absolute;
            top: -320px;
            left: -128px;
            bottom: 0;
            right: 100%;
            background: map-deep-get($colors, "brand", "secondary");

            @include media-breakpoint-up(xl) {
                left: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / -2);
                width: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / 2);
            }
        }
    }
}