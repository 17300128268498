.service-cta {
    height: calc(100% - 32px);
    margin: 80px 0;
    background-color: map-deep-get($colors, "white", "default");

    &__image-wrapper {
        position: relative;
        padding: 40px;
        padding-bottom: 0;
        background-color: map-deep-get($colors, "brand", "primary");
    }

    &__image {
        width: 100%;
        min-width: 20px;
        padding-top: 120%;
        background-size: cover;
        background-position: center;
    }

    &__linkedin {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 16px;
        background: map-deep-get($colors, "brand", "primary");

        svg {
            display: block;
            width: 24px;
            height: 24px;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__content-wrapper {
        padding: 40px;
        padding-top: 20px;
        background: map-deep-get($colors, "brand", "primary");
    }

    &__title {
        margin: 0;
        color: map-deep-get($colors, "white", "default");
    }

    &__link {
        margin-top: 1em;
        display: flex;
        align-items: center;
        color: map-deep-get($colors, "white", "default");

        &:hover {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__link-icon {
        margin-right: rem-calc(10);
        padding: 11px;
        width: 40px;
        height: 40px;
        border: 1px solid map-deep-get($colors, "white", "default");
        border-radius: 50%;

        svg {
            display: block;
            width: 18px;
            height: 18px;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__button-wrapper {
        position: relative;
        display: inline-block;
        padding: 16px 48px 16px 0;
        background-color: map-deep-get($colors, "brand", "secondary");

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: -20px;
            width: 20px;
            height: 280px;
            background-color: map-deep-get($colors, "brand", "secondary");
        }
    }
}