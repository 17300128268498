.home-services {
    &__inner {
        position: relative;
        padding: 80px 0;
        background-color: map-deep-get($colors, "brand", "primary");

        &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            bottom: -110px;
            left: -130px;
            width: 390px;
            height: 390px;
            background-color: map-deep-get($colors, "brand", "secondary");

            @include media-breakpoint-up(xl) {
                left: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / -2);
                width: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / 2 + 260px);
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -130px;
            width: 130px;
            height: 100%;
            background-color: map-deep-get($colors, "brand", "primary");

            @include media-breakpoint-up(xl) {
                right: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / -2);
                width: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / 2);
            }
        }
    }

    &__title {
        margin-top: 0;
        color: map-deep-get($colors, "white", "default");
    }

    &__text {
        color: map-deep-get($colors, "white", "default");
    }

    &__content {
        margin-bottom: 32px;
    }
}