.navigation {
    $elm: &;
    padding: 20px 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-left: 10px;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        width: auto;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 30px;
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;

        @include media-breakpoint-up(md) {
            height: 100%;
            overflow: visible;
        }

        &--active {
            transition: all ease-in-out 0s 0s;
            margin-top: -#{map-get($header-height, "small")};
            padding-top: #{map-get($header-height, "small")};
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
            height: 100%;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        display: block;
        position: relative;
        padding: 10px 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            display: inline-flex;
            align-items: center;
            padding: 0;
            margin: 0 8px;
            height: 100%;
            opacity: 1;
        }

        @include media-breakpoint-up(md) {
            &--has-sub:hover {
                #{$elm}__item {
                    color: map-deep-get($colors, "brand", "primary");
                    margin-top: 10px;
                    padding-bottom: 15px;
                }

                #{$elm}__list--sub {
                    display: block;

                    #{$elm}__item {
                        font-family: $font-family-base;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        color: map-deep-get($colors, "white", "default");
                        margin: 0;
                        padding: 5px 0;

                        &:hover {
                            text-decoration: none;

                            &::before {
                                background: map-deep-get($colors, "white", "default");
                            }
                        }
                    }
                }
            }
        }
    }

    &__item {
        display: inline-block;
        font-family: $font-family-heading;
        font-size: rem-calc(18);
        line-height: 1.25em;
        color: map-deep-get($colors, "black", "default");
        padding: 5px;
        white-space: nowrap;
        transition: $transition-default;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        &:hover {
            text-decoration: underline;
            color: map-deep-get($colors, "brand", "primary");
        }

        &--active {
            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                bottom: 50%;
                transform: translate(-50%, 20px);
                width: 5px;
                height: 5px;
                background-color: map-deep-get($colors, "brand", "secondary");
            }
        }
    }

    &__list--sub {
        display: block;
        padding: 8px;

        #{$elm}__list-item {
            display: block;
            width: 100%;

            @include media-breakpoint-up(md) {
                width: auto;
                height: auto;
                text-align: left;
            }
        }

        #{$elm}__item {
            line-height: 1.5em;
            font-size: rem-calc(16);
            text-transform: none;

            &::before {
                @include media-breakpoint-up(md) {
                    content: "";
                    display: inline-block;
                    margin-right: 1rem;
                    width: 5px;
                    height: 5px;
                    vertical-align: middle;
                    background: transparent;
                    transition: $transition-default;
                }
            }
        }

        @include media-breakpoint-up(md) {
            display: none;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding: 32px;
            padding-left: 18px;
            width: 300px;
            height: auto;
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");

            &::before {
                content: "";
                position: absolute;
                top: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }
}