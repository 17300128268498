.services {
    margin-bottom: -120px;

    &__inner {
        position: relative;
        padding: 45px 0;
        background-color: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(md) {
            padding: 85px 130px;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: -130px;
            width: 130px;
            height: 280px;
            background-color: map-deep-get($colors, "brand", "secondary");

            @include media-breakpoint-up(xl) {
                left: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / -2);
                width: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / 2);
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -130px;
            width: 130px;
            height: 100%;
            background-color: map-deep-get($colors, "brand", "primary");

            @include media-breakpoint-up(xl) {
                right: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / -2);
                width: calc((100vw - #{map-get($container-max-widths, "lg") - 4px}) / 2);
            }
        }
    }

    &__title {
        margin-top: 0;
        color: map-deep-get($colors, "white", "default");
    }

    &__content {
        margin-bottom: 32px;
    }
}