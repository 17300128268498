.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: map-deep-get($colors, "white", "default");
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    overflow: hidden;
    $header: &;

    @include media-breakpoint-up(md) {
        height: map_get($header-height, "medium");
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo {
        transition: $transition-default;
        height: 35px;
        width: 150px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            height: 45px;
            width: 263px;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }

    &__button {
        @include media-breakpoint-between(md, lg) {
            svg {
                margin: 0;
            }
        }
    }
}