.button {
    padding: 1em 1.25em;
    min-height: rem-calc(50);
    line-height: 1.25em;
    font-size: rem-calc(14);
    border-radius: 0;
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
        &:hover {
            text-decoration: none;
        }
    }

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(8);
        vertical-align: middle;
    }

    svg path {
        transition: $transition-default;
    }

    &--icon-right {
        svg {
            margin-left: rem-calc(8);
            margin-right: 0;
        }
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--large {
        @include media-breakpoint-up(md) {
            padding-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 75px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 100px;
        }
    }

    &--primary {
        color: map-deep-get($colors, "white", "default");
        background-color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                color: map-deep-get($colors, "white", "default");
                background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
            }
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &--outline-primary {
        color: map-deep-get($colors, "brand", "primary");
        border-color: map-deep-get($colors, "brand", "primary");
        background-color: transparent;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                color: map-deep-get($colors, "white", "default");
                background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);

                svg path {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &--secondary {
        color: map-deep-get($colors, "brand", "primary");
        background-color: map-deep-get($colors, "brand", "secondary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: darken(map-deep-get($colors, "brand", "secondary"), 10%);
            }
        }
    }

    &--download-primary,
    &--download-secondary {
        &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
    }

    &--download-primary {
        &::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjM0IDMuMzMzMzNMMS42NjYzNCAxOS4xNjY3TDE4LjMzMyAxOS4xNjY3TDE4LjMzMyAzLjMzMzM0TDEzLjc0OTcgMy4zMzMzM0wxMy43NDk3IDVMMTYuNjY2MyA1TDE2LjY2NjMgMTcuNUwzLjMzMyAxNy41TDMuMzMzMDEgNUw2LjI0OTY3IDVMNi4yNDk2NyAzLjMzMzMzTDEuNjY2MzQgMy4zMzMzM1pNNi4wNzcwOCA5Ljc1NTkzTDcuMjU1NiA4LjU3NzQyTDkuMTY2MzQgMTAuNDg4Mkw5LjE2NjM0IC00LjAwNjg4ZS0wN0wxMC44MzMgLTMuMjc4MzVlLTA3TDEwLjgzMyAxMC40ODgyTDEyLjc0MzggOC41Nzc0MkwxMy45MjIzIDkuNzU1OTNMOS45OTk2NyAxMy42Nzg1TDYuMDc3MDggOS43NTU5M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
        }
    }

    &--download-secondary {
        &::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjM0IDMuMzMzMzNMMS42NjYzNCAxOS4xNjY3TDE4LjMzMyAxOS4xNjY3TDE4LjMzMyAzLjMzMzM0TDEzLjc0OTcgMy4zMzMzM0wxMy43NDk3IDVMMTYuNjY2MyA1TDE2LjY2NjMgMTcuNUwzLjMzMyAxNy41TDMuMzMzMDEgNUw2LjI0OTY3IDVMNi4yNDk2NyAzLjMzMzMzTDEuNjY2MzQgMy4zMzMzM1pNNi4wNzcwOCA5Ljc1NTkzTDcuMjU1NiA4LjU3NzQyTDkuMTY2MzQgMTAuNDg4Mkw5LjE2NjM0IC00LjAwNjg4ZS0wN0wxMC44MzMgLTMuMjc4MzVlLTA3TDEwLjgzMyAxMC40ODgyTDEyLjc0MzggOC41Nzc0MkwxMy45MjIzIDkuNzU1OTNMOS45OTk2NyAxMy42Nzg1TDYuMDc3MDggOS43NTU5M1oiIGZpbGw9IiMwOTNBNTkiLz4KPC9zdmc+Cg==");
        }
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "white", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: lighten(map-deep-get($colors, "black", "default"), 10%);
            }
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "gray", "light");
            }
        }
    }
}
