.team {
    margin-top: 200px;
    $block: &;

    @include media-breakpoint-up(md) {
        margin-top: 240px;
    }

    &--primary {
        margin-top: 120px;
        margin-bottom: -120px;
        padding: 120px 0;
        background-color: map-deep-get($colors, "brand", "primary");

        #{$block}__title {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 24px;
        color: map-deep-get($colors, "brand", "primary");
    }
}