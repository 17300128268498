.home-about {
    margin-bottom: 120px;

    &__image2 {
        min-height: 100px;
        width: 100%;
    }

    &__image3 {
        min-height: 100px;
        width: 100%;
    }

    &__image4 {
        margin-top: 20px;
        min-height: 100px;
        width: 100%;

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: rem-calc(80);
    }
}