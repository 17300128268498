@font-face {
    font-family: Helvetica Neue;
    src: url("../../../fonts/HelveticaNeue-Medium.woff2") format("woff2"),
        url("../../../fonts/HelveticaNeue-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: HighVoltage Rough;
    src: url("../../../fonts/HighVoltageRough.woff2") format("woff2"),
        url("../../../fonts/HighVoltageRough.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Helvetica Neue;
    src: url("../../../fonts/HelveticaNeue-Bold.woff2") format("woff2"),
        url("../../../fonts/HelveticaNeue-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Helvetica Neue;
    src: url("../../../fonts/HelveticaNeue-Roman.woff2") format("woff2"),
        url("../../../fonts/HelveticaNeue-Roman.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Helvetica Neue;
    src: url("../../../fonts/HelveticaNeue-Light.woff2") format("woff2"),
        url("../../../fonts/HelveticaNeue-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Helvetica Neue;
    src: url("../../../fonts/HelveticaNeue-Italic.woff2") format("woff2"),
        url("../../../fonts/HelveticaNeue-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}
