.footer {
    flex: 1 1 100%;

    a:not(.button) {
        color: map-deep-get($colors, "black", "default");
    }

    &__top {
        padding: 72px 0;
        text-align: center;
        color: map-deep-get($colors, "brand", "primary");
        background: map-deep-get($colors, "brand", "secondary");

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    &__cta-title {
        margin-top: 0;
        margin-bottom: 24px;
    }

    &__contact-title {
        margin-top: 2em;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    &__bottom {
        padding-top: 32px;
        padding-bottom: 32px;
        color: map-deep-get($colors, "brand", "primary");
        background-color: #ffd32b;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        /* stylelint-disable */
        a:not(.button) {
            color: map-deep-get($colors, "brand", "primary");
            text-decoration: underline;
        }
        /* stylelint-enable */
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        color: map-deep-get($colors, "brand", "primary");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;
        }
    }

    &__item {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &__reference {
        font-size: 14px;
        color: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}
