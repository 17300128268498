.main {
    overflow: hidden;
    max-width: 100%;
    padding-top: #{map_get($header-height, "small")};
    padding-bottom: 120px;
    transition: $transition-default;
    flex: 1 1 100%;
    //height: 100%;

    @include media-breakpoint-up(md) {
        padding-top: #{map_get($header-height, "medium")};
    }

    @include media-breakpoint-up(lg) {
        padding-top: #{map_get($header-height, "large")};
    }
}