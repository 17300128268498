.page {
    &__title {
        margin-top: 72px;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__main {
        background-color: map-deep-get($colors, "white", "default");

        &--shifted {
            @include media-breakpoint-up(md) {
                position: relative;
                margin-top: -100px;
            }
        }
    }
}