﻿h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-heading;
    font-weight: 400;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
}

p {
    margin-top: 0;
}

h1, .h1 {
    font-size: rem-calc(90);
    line-height: 1em;

    @include media-breakpoint-down(md) {
        font-size: rem-calc(74);
    }

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(40);
    }
}

h2, .h2 {
    font-size: rem-calc(75);
    line-height: 1.08em;

    @include media-breakpoint-down(md) {
        font-size: rem-calc(66);
    }

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(36);
    }
}

h3, .h3 {
    font-size: rem-calc(60);
    line-height: 1.2em;

    @include media-breakpoint-down(md) {
        font-size: rem-calc(58);
    }

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(32);
    }
}

h4, .h4 {
    font-size: rem-calc(50);
    line-height: 1.2em;

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(28);
    }
}

h5, .h5 {
    font-size: rem-calc(38);
    line-height: 1.26em;

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(24);
    }
}

h6, .h6 {
    font-size: rem-calc(25);
    line-height: 1.4em;

    @include media-breakpoint-down(sm) {
        font-size: rem-calc(20);
    }
}