.person-card {
    display: flex;
    flex-flow: column;
    height: calc(100% - 32px);
    margin-bottom: 32px;
    background-color: map-deep-get($colors, "white", "default");

    &__image-wrapper {
        position: relative;
    }

    &__image {
        width: 100%;
        min-width: 20px;
        padding-top: 120%;
        background-size: cover;
        background-position: center;
    }

    &__linkedin {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 16px;
        background: map-deep-get($colors, "brand", "primary");

        svg {
            display: block;
            width: 24px;
            height: 24px;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__content-wrapper {
        flex: 1 0 auto;
        padding: 20px 24px;
        border: 1px solid map-deep-get($colors, "brand", "primary");
        border-top: 0;
    }

    &__title {
        margin: 0;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__link {
        margin-top: 1em;
        display: flex;
        align-items: center;
        overflow-wrap: break-word;
        word-break: break-all;
    }

    &__link-icon {
        margin-right: rem-calc(10);
        padding: 11px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: map-deep-get($colors, "brand", "primary");

        svg {
            display: block;
            width: 18px;
            height: 18px;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }
}