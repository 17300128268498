.service-card {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 24px;
    margin-bottom: 32px;
    min-height: 120px;
    height: calc(100% - 32px);
    border: 1px solid map-deep-get($colors, "white", "default");
    $block: &;

    @include media-breakpoint-up(md) {
        min-height: 220px;
    }

    &:hover {
        text-decoration: none;

        #{$block}__image {
            opacity: .5;
        }
    }

    &__image {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__title {
        position: relative;
        margin: 0;
        text-align: center;
        color: map-deep-get($colors, "white", "default");
    }
}